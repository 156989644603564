import React from "react"
import * as R from "ramda"
import { graphql } from "gatsby"
import Box from "@material-ui/core/Box"
import Backgroundimage from "gatsby-background-image"
import Hidden from "@material-ui/core/Hidden"
import Seo from "../components/seo"
import Prose from "../components/prose"
import RichText from "../components/richText"
import LayoutController from "../components/layoutController"

const SimplePage = ({ data }) => {
  const pageContent = R.path(["contentfulContent"], data)
  const language = R.path(["node_locale"], pageContent)
  const { bgimageBlue } = data
  const bgimageBlueImage = R.path(["childImageSharp", "fixed"], bgimageBlue)
  const title = R.path(["otsikko"], pageContent)
  const sisaltotekstiLyhytVersio = R.path(
    ["sisaltotekstiLyhytVersio"],
    pageContent
  )
  const richTextContent = R.path(["richText"], pageContent)

  return (
    <LayoutController language={language}>
    <Box className="max-w-3xl mx-auto my-8" style={{ position: "relative" }}>
      <Seo
        seoTitle={title}
        seoDesc={sisaltotekstiLyhytVersio}
        url={typeof window !== "undefined" ? window.location.href : ""}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "35px",
          padding: "0 20px",
        }}
      >
        <Box style={{ maxWidth: "1200px", width: "100%" }}>
          <h1>{title}</h1>
          {richTextContent && <RichText richText={richTextContent} />}
        </Box>
      </Box>
      <Hidden smDown>
        <Backgroundimage
          fixed={bgimageBlueImage}
          style={{
            zIndex: "-100",
            backgroundPosition: "0% 0%",
            width: "561px",
            height: "688px",
            marginTop: "-415px",
            position: "absolute",
            right: "-250px",
            backgroundSize: "contain",
          }}
        />
      </Hidden>
    </Box>
    </LayoutController>
  )
}

export default SimplePage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query simplePageQuery($slug: String) {
    contentfulContent: contentfulYleissivu(slug: { eq: $slug }) {
      id
      slug
      node_locale
      otsikko
      slug
      sisaltotekstiLyhytVersio
      richText {
        raw
        references {
          ... on Node {
            ... on ContentfulAsset {
              id
              contentful_id
              __typename
              title
              description
              gatsbyImageData(width: 800)
              file {
                url
                fileName
              }
            }
          }
          ... on Node {
            ... on ContentfulHenkilo {
              contentful_id
              id
              __typename
              name
              contentText {
                contentText
              }
              image {
                gatsbyImageData(width: 300)
              }
            }
          }
          ... on Node {
            ... on ContentfulNosto {
              id
              contentful_id
              __typename
              title
              contentText {
                contentText
              }
              image {
                gatsbyImageData(width: 500)
              }
              buttonText
              buttonLink
              backgroundColor
              reverseImage
            }
          }
          ... on Node {
            ... on ContentfulPainike {
              id
              contentful_id
              __typename
              ctaText
              ctaTarget
              ctaColor
            }
          }
        }
      }
    }
    bgimageBlue: file(relativePath: { eq: "Group 258round.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 561
          height: 688
          layout: FIXED
        )
      }
    }
  }
`
